/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect, useRef } from 'react';
import {
    format,
} from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
    func, bool,
} from 'prop-types';
import ItemImage from '../ItemImage/ItemImage';

import {
    getOrderTotalProduct,
    getCartOrder,
    getTotalItemInCart,
} from '../../../../../../../../state/ducks/Common/Common-Selectors';
import DeleteItemButton from './DeleteItemButton';
import { setCurrencyFormatforTotalOrder } from '../Helper/Helper';
import FlashDealPageContainer from '../../../../../../GraphqlComponents/FlashTodayDeal/FlashDealPageContainer';
import ContinueShoppingButton from './ContinueShoppingButton';
import NonStandardItems from './NonStandardItem';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { clearAddToCartError } from '../../../../../../../../state/ducks/AddToCart/AddToCart-Actions';
import { trackEvent } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    containerDrawer: {
        width: '492px',
        [theme.breakpoints.down(1366)]: {
            width: '461px',
        },
        [theme.breakpoints.down(1024)]: {
            width: '380px',
        },
        [theme.breakpoints.down(415)]: {
            width: '372px',
        },
    },
    list: {
        maxHeight: 'calc(100% - 190px)',
        minHeight: 'calc(100% - 190px)',
        overflowY: 'auto',
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        margin: '0rem 3px 0px 0px',
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'rgba(156, 156, 156, 1)',  // scroll bar color
        },
        '&::-webkit-scrollbar': {  /* Works on scrollbar for Chrome, Safari and Opera */
            width: '5px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
        },
        scrollbarColor: 'rgba(156, 156, 156, 1) transparent',  /* Works on Firefox */
    },
    listContainer: {
        maxHeight: 'calc(100% - 465.2px)',
        minHeight: 'calc(100% - 465.2px)',
    },
    orderItemImg: {
        paddingRight: '1rem',
        '@media screen and (max-height: 680px)': {
            flexBasis: '25.6%',
        },
        '&>a': {
            display: 'flex',
            maxWidth: '131px',
            minHeight: '144px',
            '& img': {
                width: '100%',
                height: '100%',
            },
            [theme.breakpoints.down(1366)]: {
                minHeight: 'auto',
            },
            '@media screen and (max-height: 680px)': {
                width: 'auto',
                minHeight: 'auto',
            },
        },

    },
    productContainer: {
        padding: '26px 25px 30px 16px',
        flex: '0 0 auto',
        position: 'relative',
        fontFamily: theme.palette.slideOutMiniCartFont || 'inherit',
        '&::before': {
            content: '""',
            position: 'absolute',
            borderBottom: '1px solid rgba(47, 47, 47, 0.2)',
            width: '94%',
            bottom: '0',
        },
        [theme.breakpoints.down(768)]: {
            padding: '16px 7px 16px 10px ',
        },
        '@media screen and (max-height: 680px)': {
            padding: '13px 25px 15px 16px',
        },
    },
    itemDetailsContainer: {
        '@media screen and (max-height: 680px)': {
            flexGrow: 1,
            maxWidth: '100%',
        },
    },
    viewCartBtn: {
        fontSize: '24px',
        fontWeight: '700 !important',
        margin: 0,
        padding: 0,
        [theme.breakpoints.down(415)]: {
            fontSize: '20px',
        },
        fontFamily: theme.palette.slideOutMiniCartFont || 'inherit',
    },
    borderBottom: {
        borderBottom: '1px solid rgba(47, 47, 47, 0.2)',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 0px 10px',
        margin: '0rem 1rem',
    },
    container: {
        position: 'fixed',
        top: '0',
        right: '0',
        height: '100%',
        width: '492px',
        zIndex: '11',
        [theme.breakpoints.down(1366)]: {
            width: '461px',
        },
        [theme.breakpoints.down(1024)]: {
            width: '380px',
        },
        [theme.breakpoints.down(415)]: {
            width: '372px',
        },
    },
    cartCount: {
        paddingLeft: '7px',
        fontSize: '20px',
        fontWeight: 400,
    },
    productName: {
        fontSize: '16px',
        margin: 0,
        padding: ' 0px 0px 13px',
        fontWeight: '700 !important',
    },
    btnCheckout: {
        backgroundColor: theme.palette.primaryButton,
        color: theme.palette.colorPrimaryContrast,
        fontFamily: theme.palette.slideOutMiniCartFont || 'inherit',
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '20px auto',
        width: '100%',
        minHeight: '50px',
        borderRadius: '0',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.primaryButton,
        },
        '@media screen and (min-width:740px) and (max-width: 1366px)': {
            margin: '10px auto',
        },
    },
    bottomContainer: {
        background: theme.palette.miniCartBg || '#F2F2F2',
        fontFamily: theme.palette.slideOutMiniCartFont || 'inherit',
        padding: '12px 16px 0px',
        '& p': {
            fontSize: '16px',
            lineHeight: '24px',
        },
        '& p:last-child': {
            paddingRight: '12px',
        },

    },
    subtotal: {
        fontFamily: theme.palette.slideOutMiniCartFont || 'inherit',
    },
    priceAttributeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    closeButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        fontSize: '1rem',
        lineHeight: '1.8rem',
        color: theme.palette?.primary?.main || '#2F2F2F',
        '& span': {
            alignItems: 'flex-start',
        },
        '& svg': {
            width: '25px',
            fill: theme.palette?.primary?.main || '#2F2F2F',
        },
    },
    flashContainer: {
        position: 'relative',
        background: theme.palette.miniCartBg || '#F2F2F2',
        width: '100%',
        minHeight: '276.23px', /* Set a reasonable min-height to reserve space */
    },
    setItemLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    salePrice: {
        fontWeight: '700',
        color: theme.palette.pdp?.salePrice || '#A1001A',
        fontSize: '16px',
    },
    defaultPrice: {
        fontSize: '16px',
        color: theme.palette?.slideOutMiniCartMainColor || theme.palette?.primary?.main || '#2F2F2F',
    },
    emptyCartContainer: {
        height: 'calc(100% - 70px)',
        overflowY: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyCartText: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    emptyCart: {
        marginTop: '70px',
    },
    continueShoppingBtn: {
        padding: '15px 0',
        maxWidth: '300px',
        width: '100%',
        display: 'block',
        textAlign: 'center',
        margin: '0 auto',
        '& a': {
            width: '100%',
        },
    },
    skuCode: {
        fontSize: '12px',
        color: theme.palette.cta4 || '#595959',
        marginBottom: '16px',
        '@media screen and (max-height: 680px)': {
            marginBottom: '4px',
        },
    },
    skuCodeV2: {
        fontSize: '12px',
        color: theme.palette.cta4 || '#595959',
        marginBottom: '5px',
        '@media screen and (max-height: 680px)': {
            marginBottom: '4px',
        },
    },
    highLightedBgColor: {
        '&:nth-last-child(2)': {
            backgroundColor: theme.palette.footerLinkImgBg || '#E8F1ED',
        },
    },
    nonStandardItems: {
        display: 'flex',
        justifyContent: 'flex-end',
        '@media screen and (max-height: 680px)': {
            '&>div': {
                flexGrow: 1,
                maxWidth: '75%',
            },
        },
    },
    deliveryText: {
        fontSize: '12px',
        color: '#2F2F2F',
        marginBottom: '5px',
        fontFamily: 'Helvetica',
        lineHeight: '18px',
    },
}));

// getting previous state
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const SlideOutMiniCart = ({
    closeDrawer, openDrawer, handleCheckout,
}) => {
    const cartCount =  useSelector(getTotalItemInCart);
    const orderTotalProduct =  useSelector(getOrderTotalProduct);
    const cartOrderDetails =  useSelector(getCartOrder);

    const dispatch = useDispatch();
    const { recipients = [] } = cartOrderDetails || {};
    const classes = useStyles();
    const [isOpen, setModal] = useState(true);
    const lastElementRef = useRef();
    const featureFlags =  useSelector(getFeatureFlags);

    const history = useHistory();
    const splitSkuId = (partNumber) => {
        const skuId = partNumber.match(/[^-_]*$/);
        return (skuId?.length > 0) ? skuId[0] : '';
    };

    useEffect(() => {
        if (lastElementRef?.current) {
            lastElementRef.current?.scrollIntoView(
                {
                    scrollBehavior: 'smooth',
                    block: 'end',
                });
        }
    });

    // set state for the background class
    const [backgroundClass, setBackgroundClass] = useState(false);
    const prevCount = usePrevious(cartCount);

    // set state for the background class
    useEffect(() => {
        if ((prevCount?.cartCount !== cartCount  && cartCount > prevCount) || !prevCount) {
            setBackgroundClass(true);
            setTimeout(() => {
                setBackgroundClass(false);
            }, 5000);
        }
    }, [openDrawer, cartCount]);

    const renderProductName = (productData) => {
        const forceProductName = ['1002'];
        let productName = productData?.parentProductName || productData?.productName || '';
        const getCrossBrand = featureFlags?.['which-cross-merch-brands-list'][productData?.brandCode];
        if (getCrossBrand && getCrossBrand === 'food' && !forceProductName.includes(productData?.brandCode)) {
            productName =  productData?.productName || productData?.parentProductName || '';
        }
        return productName;
    };

    const renderDeliveryMessage = (cartItem) => {
        if (cartItem?.deliveryInfo?.deliveryRangeStartDate) {
            return `Deliver Between ${format(new Date(cartItem?.deliveryInfo?.deliveryRangeStartDate?.slice(0, 10) || ''), 'MM/dd')} - ${format(new Date(cartItem?.deliveryInfo?.deliveryDate?.slice(0, 10) || ''), 'MM/dd')}`;
        }
        return `Deliver By ${format((new Date(cartItem?.deliveryInfo?.deliveryDate?.slice(0, 10) || '')), 'EEEE')} - ${format(new Date(cartItem?.deliveryInfo?.deliveryDate?.slice(0, 10) || ''), 'MM/dd')}`;
    };

    return (
        <>
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => {
                    closeDrawer();
                    dispatch(clearAddToCartError());
                }}
                classes={{ paper: classes.containerDrawer }}
            >
                <Grid container className={`SlideOutMiniCart ${classes.container}`}>
                    <Grid item xs={12} className={`${classes.header} ${classes.borderBottom}`}>
                        <h4 className={classes.viewCartBtn}>
                            Your Cart {cartCount > 0 && <span className={classes.cartCount}>({cartCount})</span>}
                        </h4>
                        <IconButton
                            className={classes.closeButton}
                            aria-label="close"
                            onClick={() => {
                                closeDrawer();
                                dispatch(clearAddToCartError());
                                dispatch(trackEvent({
                                    eventName: 'feature_interaction',
                                    eventCategory: 'Product Page',
                                    eventAction: 'Mini-Cart',
                                    eventLabel: 'Close button clicked',
                                }));
                            }}
                            disableRipple
                        >
                            Close <CloseIcon />
                        </IconButton>
                    </Grid>
                    {cartCount > 0
                        ? (
                            <>
                                <Grid item xs={12}   className={`${classes.list} ${isOpen ? classes.listContainer : ''}`}>
                                    { recipients.map(
                                        (items) => (items.cartItems.map(
                                            (cartItem) => {
                                                if (!cartItem.cartItemId) { return null; }
                                                return (
                                                    <Grid container key={`productList-${cartItem.cartItemId}${1}`} className={`${classes.productContainer} ${backgroundClass ? classes.highLightedBgColor : ''}`}>
                                                        <Grid item xs={4} className={classes.orderItemImg}>
                                                            <Link to={cartItem.product.seoUrl || '/'}>
                                                                <ItemImage
                                                                    imagePath={cartItem.product.imagePath || ''}
                                                                    imageName={cartItem.product.imageName || ''}
                                                                    typeImg="c"
                                                                    size="?width=150&sharpen=a0.5,r1,t1&auto=webp"
                                                                />
                                                            </Link>
                                                        </Grid>
                                                        <Grid xs={8} item className={classes.itemDetailsContainer}>
                                                            <Grid className={classes.orderItemDetails} container>
                                                                {/* Product Details Start */}
                                                                <Grid item xs={10}><h6 className={classes.productName}>{ReactHtmlParser(renderProductName(cartItem.product))}</h6></Grid>
                                                                <Grid item xs={2}>
                                                                    <DeleteItemButton
                                                                        orderItemId={cartItem.cartItemId}
                                                                        pageProcess="checkout-food"
                                                                        skuCode={cartItem.product.productCode}
                                                                        parentSkuCode={cartItem?.product.parentProductCode}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} className={featureFlags['is-new-delivery-calendar-enabled'] ? classes.skuCodeV2 : classes.skuCode}>Item#: <span>{splitSkuId(cartItem.product.productCode || '')}</span></Grid>
                                                                {featureFlags['is-new-delivery-calendar-enabled'] && cartItem?.deliveryInfo && cartItem?.deliveryInfo?.deliveryDate && (
                                                                    <>
                                                                        <Grid item xs={12} className={classes.deliveryText}>Delivery Zip Code: <span>{cartItem?.deliveryInfo?.zipCode}</span></Grid>
                                                                        <Grid item xs={12} className={classes.deliveryText}>{renderDeliveryMessage(cartItem)}</Grid>
                                                                    </>
                                                                )}
                                                                <Grid item xs={9}>Qty: <span>{cartItem.itemInfo?.quantity}</span></Grid>
                                                                <Grid item xs={3}>
                                                                    <span className={classes.setItemLayout}>
                                                                        { cartItem.product.listPrice !== cartItem.product.salePrice
                                                                            ? (
                                                                                <>
                                                                                    <s>
                                                                                        {`$${cartItem.product.listPrice}`}
                                                                                    </s>
                                                                                    <span className={classes.salePrice}>
                                                                                        {`$${cartItem.product.salePrice}`}
                                                                                    </span>
                                                                                </>
                                                                            )
                                                                            : <span className={classes.defaultPrice}><strong>{`$${cartItem.product.listPrice}`}</strong></span>}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.nonStandardItems}>
                                                            <Grid item xs={8}>
                                                                <NonStandardItems
                                                                    itemDetails={cartItem}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            },
                                        )
                                        ),
                                    )}
                                    {/* getting this last element with scroll */}
                                    <span ref={lastElementRef} />
                                </Grid>
                                {isOpen && <Grid item xs={12} className={classes.flashContainer}><FlashDealPageContainer source="checkout_product_recommendations" tracking={{ eventCategory: 'Special Items', eventAction: 'Mini Cart' }} handleCloseTo={() => setModal(false)} /></Grid>}
                                <Grid item xs={12} className={`${classes.bottomContainer}`}>
                                    <div className={classes.priceAttributeContainer}>
                                        <Typography className={classes.subtotal} variant="body2">
                                            <b>Subtotal</b>
                                        </Typography>
                                        <Typography className={classes.subtotal} variant="body2">
                                            <b>{`$${(setCurrencyFormatforTotalOrder(Number(orderTotalProduct || 0).toFixed(2)))}`}</b>
                                        </Typography>
                                    </div>
                                    <Button
                                        color="primary"
                                        className={`${classes.btnCheckout}`}
                                        onClick={(event) => {
                                            dispatch(trackEvent({
                                                eventName: 'feature_interaction',
                                                eventCategory: 'Product Page',
                                                eventAction: 'Mini-Cart',
                                                eventLabel: 'Checkout button clicked',
                                            }));
                                            if (featureFlags['is-wrapup-enabled']) {
                                                history.push('/wrap-up');
                                                dispatch(trackEvent({
                                                    eventName: 'feature_interaction',
                                                    eventCategory: 'Wrap Up Products',
                                                    eventAction: 'View',
                                                    eventLabel: 'impression',
                                                }));
                                                return;
                                            }
                                            handleCheckout('checkout', event);
                                        }}
                                    >
                                        Checkout
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <Grid container className={classes.emptyCartContainer}>
                                <Grid item xs={12} className={classes.emptyCart}>
                                    <Typography data-test="mini-cart-header-text" variant="h6" className={classes.emptyCartText}>
                                        Your cart is currently empty.
                                    </Typography>
                                    <div className={classes.continueShoppingBtn}>
                                        <ContinueShoppingButton />
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </Drawer>
        </>
    );
};

SlideOutMiniCart.propTypes = {
    openDrawer: bool.isRequired,
    closeDrawer: func.isRequired,
    handleCheckout: func.isRequired,
};

export default SlideOutMiniCart;
